export const NftIcon = (props) => <a
    href="https://joepegs.com/mint/avalanche/chuck-nft"
    className={`x-link ${props.ml ? "ml-2" : ""} ${props.title ? "title" : ""}`}
    target="_blank"
    rel="noreferrer"
    title="NFT collections"
    >
    <div className="x">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="24.000000pt" height="24.000000pt" viewBox="0 0 24.000000 24.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(-2.500000,26.000000) scale(0.060000,-0.060000)"
            fill="currentColor" stroke="none">
            <path d="M193 429 c-54 -35 -39 -53 18 -23 29 15 32 14 107 -29 l77 -45 3 -89
            c3 -87 2 -91 -23 -107 -14 -9 -25 -21 -25 -26 0 -16 26 -11 54 11 25 20 26 25
            26 118 0 81 -3 101 -17 113 -30 26 -158 98 -174 98 -8 0 -29 -10 -46 -21z"/>
            <path d="M76 359 c-25 -20 -26 -25 -26 -118 0 -113 -5 -105 108 -170 75 -44
            97 -47 136 -18 31 23 11 40 -25 21 -29 -15 -32 -14 -107 29 l-77 45 -3 89 c-3
            87 -2 91 23 107 14 9 25 21 25 26 0 16 -26 11 -54 -11z"/>
            <path d="M110 240 c0 -49 14 -67 27 -34 6 15 8 15 20 -2 19 -26 35 -5 31 41
            -3 42 -28 62 -28 24 l0 -23 -16 22 c-24 34 -34 26 -34 -28z"/>
            <path d="M217 283 c-11 -10 -8 -81 4 -88 6 -4 13 2 16 14 3 12 12 21 19 21 8
            0 14 5 14 10 0 6 -8 10 -17 10 -17 1 -17 1 0 11 9 5 17 14 17 19 0 11 -43 14
            -53 3z"/>
            <path d="M300 281 c0 -6 5 -13 10 -16 6 -3 10 -22 10 -41 0 -19 5 -34 10 -34
            6 0 10 15 10 34 0 21 6 36 15 40 27 10 15 26 -20 26 -19 0 -35 -4 -35 -9z"/>
            </g>
        </svg>
    </div>
    {props.title}
</a>
