import { useEffect, useState } from "react";

export const CountdownTimer = ({ toDate }) => {
  const calculateTimeDifference = () => {
    return Math.floor((new Date().getTime() - toDate) / 1000);
  };

  const [timeDifference, setTimeDifference] = useState(calculateTimeDifference);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeDifference(calculateTimeDifference);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours ? `${hours}h` : ""} ${
      minutes ? `${minutes}min` : ""
    } ${remainingSeconds}s`;
  };

  if (!toDate) return <>Time : 0s</>;

  return <>Time : {formatTime(timeDifference)}</>;
};
