import axios from "axios";

class MoralisService {
  async getNftByContract(addressWallet, cursor) {
    return axios.get(
      cursor
        ? `${process.env.REACT_APP_API_MORALIS}/${addressWallet}/nft?chain=avalanche&token_addresses%5B0%5D=${process.env.REACT_APP_ADDRESS_CHUCK_NFT}&cursor=${cursor}`
        : `${process.env.REACT_APP_API_MORALIS}/${addressWallet}/nft?chain=avalanche&token_addresses%5B0%5D=${process.env.REACT_APP_ADDRESS_CHUCK_NFT}`,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_MORALIC_KEY,
        },
      }
    );
  }
}

export const moralisService = new MoralisService();
