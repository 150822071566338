import { Link } from "react-router-dom";

export const PlayIcon = (props) => <Link
    to="/play"
    className={`x-link ${props.ml ? "ml-2" : ""} ${props.title ? "title" : ""}`}
    title="Play"
    >
    <div className="x">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="24.000000pt" height="24.000000pt" viewBox="0 0 24.000000 24.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)"
            fill="currentColor" stroke="none">
            <path d="M35 198 c4 -12 18 -35 31 -49 27 -28 23 -39 -14 -39 -18 0 -23 -4
            -18 -16 4 -10 -1 -25 -11 -36 -24 -27 -2 -49 25 -25 11 10 26 15 34 12 11 -5
            17 2 20 23 3 22 1 29 -9 25 -8 -3 -11 -12 -8 -21 5 -12 1 -12 -15 3 -21 19
            -27 30 -9 20 6 -4 16 0 22 9 11 15 14 15 35 1 13 -10 22 -27 22 -42 0 -19 4
            -24 16 -19 10 4 25 -1 36 -11 14 -12 21 -14 30 -5 9 9 7 16 -5 30 -10 11 -15
            26 -11 36 5 12 0 16 -18 16 -14 0 -33 9 -42 19 -17 18 -17 21 -1 45 19 28 45
            35 45 12 0 -8 -10 -22 -22 -30 -12 -9 -18 -16 -12 -16 15 0 40 30 49 58 6 22
            5 23 -17 17 -12 -4 -33 -17 -44 -30 l-21 -23 -24 23 c-13 13 -35 26 -47 30
            -22 6 -23 5 -17 -17z m85 -53 c25 -29 51 -52 58 -50 17 6 15 1 -8 -20 -15 -13
            -19 -14 -16 -4 3 8 -16 32 -49 59 -30 25 -55 51 -55 58 0 26 27 9 70 -43z
            m-85 -105 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11
            -10z m185 0 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4
            -10z"/>
            </g>
        </svg>
    </div>
    {props.title}
</Link>
