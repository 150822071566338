import { useEffect, useState } from "react";
import { useWeb3 } from "../../context/web3.context";
import { gameService } from "../../services/game.service";
import { Avatar } from "../../components/avatar";
import { gameIntro, renderGame } from "../../helpers/gameUtils";

const Game = () => {
  const [socket, setSocket] = useState(null);
  const [chatMessage, setChatMessage] = useState("");

  const { account } = useWeb3();
  const [game, setGame] = useState();
  // const [onLobby, setOnLobby] = useState(false);

  const closeWs = () => {
    socket?.close();
    setSocket(null);
  }
  const openWs = () => {
    if (!socket) {
      let ws = new WebSocket(process.env.REACT_APP_WEBSOCKET_BASE_URL);
      setSocket(ws);

      ws.onopen = () => {
        console.log('Connexion WebSocket établie.');
      };

      ws.onmessage = (event) => {
        const message = JSON.parse(event.data);
        // console.log('Message du serveur : ', message);
        setGame(message);
      };

      ws.onerror = (error) => {
        console.error('Erreur de connexion WebSocket : ', error);
      };

      ws.onclose = () => {
        console.log('Connexion WebSocket fermée.');
      };
    }
  }

  const [currentDate, setCurrentDate] = useState(new Date());
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date())
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    //   if (game) {
    //     openWs()
    // }

    return async () => {
      closeWs();
      if (game) {
        await gameService.leave({gameId: game._id});
        setGame(null);
      }
    };
  }, []);

  const joinGame = async () => {
    openWs();
    await gameService.joinGame();
  }
  const startGame = async () => {
    await gameService.startGame({gameId: game._id});
  }

  const setReady = async () => {
    await gameService.ready({gameId: game._id});
  }

  const leave = async () => {
    closeWs();
    if (game) {
      await gameService.leave({gameId: game._id});
      setGame(null);
    }
  }

  const renderJoiningGame = () => <div className="part">
  <h1>PLAY</h1>
  <div className="flex flex-center">
    <div className="relative">
      <div className="card flex flex-column gap-20">
        <b>Challenge the community while playing games.</b>
        <ul>
          <li>A session costs x $CHUCK</li>
          <li>A session has 5 rounds of 2min each</li>
          <li>Sessions have random games</li>
          <li>
            The more you win, the more rewards you will earn at the end of
            the month
          </li>
          <li>You can use Discord vocal chat</li>
          <li>Don't forget to have fun</li>
        </ul>
        <button
          disabled={!(account && window.location.search === "?test")}
          onClick={joinGame}
          style={{ cursor: !account ? "default" : "pointer" }}
        >
          {account ? "PARTICIPATE" : "NOT CONNECTED"}
        </button>
      </div>
    </div>
    <div className="part-right">
      <img src="/banner7.png" alt="Chuck" width={800} async />
    </div>
  </div>
</div>

  const renderAddress = (a) => {
    if (!a) return "";
    return <span title={a}>{a.slice(0, 5)}...{a.slice(-5)}</span>;
  }

  const renderIcon = (u) => {
    return <Avatar small />;
  }

  const renderUser = (u) => <div className="flex" key={u.id}>
    {renderIcon(u)}
    <div className="flex flex-column chat-name">
    <div>{u.userName || u.id }</div>
    <div>{renderAddress(u.id)}</div>
    { game.startedAt && <div>{u.victory}🏆</div> }
    { !game.startedAt && <div>{u.readyAt ? "✅": "❌"}</div>}
    </div>
  </div>

  const renderColumn = (key, value) => <div className="flex gap-20 space-between">
    <div><b>{key}</b></div>
    <div>{value}</div>
    </div>

  const renderWaiting = () => {
    const readyCount = game.users?.filter(a => a.readyAt && !a.deletedAt).length;

    return `Waiting: ${readyCount}✅ / ${game.users?.filter(a => !a.deletedAt).length}`;
  }

  const renderGameInformations = () => {
    const isPlaying = game?.startedAt && new Date(game.sessions[game.currentSession].startedAt) < currentDate && currentDate < new Date(game.sessions[game.currentSession].finishedAt);
    const timeLeft = game.startedAt && isPlaying
    ? `${Math.floor((new Date(game.sessions[game.currentSession].finishedAt).getTime() - new Date(currentDate).getTime()) / 1000)}s` : "/";

    return <div>
      <div className="flex gap-20">
        <div className="flex width-50 flex-column">
          <div>{ renderColumn("Created at", game.createdAt?.replace("Z", "").replace("T", " "))}</div>
          <div>{ renderColumn("Created by", renderAddress(game.createdBy))}</div>
          <div>{ renderColumn("Status", game.startedAt ? "Launched" : game.closedAt ? "Closed" : renderWaiting())}</div>
        </div>
        <div className="flex width-50 flex-column">
          <div>{ renderColumn("Number of sessions:", game.sessions?.length)}</div>
          <div>{ renderColumn("Current session", game.currentSession + 1 ?? "/")}</div>
          <div>{ renderColumn("Time Left", timeLeft)}</div>
        </div>
      </div>
      
    </div>
  }

  const renderActionButtons = () => {
    const currentUser = game.users?.find(a => a.id === account && !a.deletedAt);
    const isReady = currentUser?.readyAt;
    const nbReady = game.users?.filter(a => a.readyAt && !a.deletedAt).length;

    return <div className={`game-button`}>
      { nbReady >= 2 && !game.startedAt && <button onClick={startGame} className="isReady">Start</button> }
      { !game.startedAt && <button onClick={!isReady ? setReady : null} className={isReady ? "isReady" : ""}>{ !isReady ? "I'm ready" : "Ready !"}</button> }
      <button onClick={leave}>Leave</button>
    </div>
  }

  const renderGameContainer = () => {    
    return (
      <div className="part full">
        <div className="flex flex-center gap-20">
            { renderParticipantPanel() }
            { renderGamePanel() }
            { renderChatPanel() }
        </div>
      </div>
    )
  }

  const renderGameClosed = () => <div>
    <h2>Game Closed</h2>
    <div>The game is finished, chuckers. I hope you enjoyed it. Whether it was a hard-fought battle of wits or a casual match played for fun, the thrill of competition and the camaraderie shared across the board are what make games like this truly memorable. As the final pieces are laid to rest and the last moves are made, remember that it's not just about the outcome, but the journey of strategy and skill that brought us here.</div>
    <div>Now, as the dust settles and the board clears, take a moment to reflect on the twists and turns of the game. Cherish the memories created and the bonds strengthened through this shared experience. Whether victor or vanquished, in the world of games, every encounter is an opportunity to learn, grow, and revel in the joy of play. Until our next game, may the spirit of competition and the joy of play continue to unite us, on and off the board.</div>
    <div>Here is the leaderboard:</div>
    <div className="starting">
      {game.users
        .filter(a => !a.deletedAt && a.readyAt)
        .map(u => <div><b>{u.userName}</b>: {u.victory}🏆</div>)
      }
    </div>
  </div>

  const renderGameBoard = () => {    
    if (!game?.startedAt)
      return <h2>Game not started</h2>

    if (game?.closedAt)
      return renderGameClosed();
    
    const isIntro = new Date(game.sessions[game.currentSession].introAt) <= currentDate && currentDate <= new Date(game.sessions[game.currentSession].startedAt);

    if (isIntro) {
      const timeLeft = Math.floor((new Date(game.sessions[game.currentSession].startedAt).getTime() - new Date(currentDate).getTime()) / 1000);
      const gameType = game.sessions[game.currentSession].gameType;
      const gameInfo = gameIntro[gameType];
      return <>
        {renderSessionDescription()}
        <div className="starting"><b>{gameInfo.title}</b></div>
        <div>{gameInfo.description}</div>
        <div className="starting"><b>Starting in {timeLeft}s</b></div>
      </>
    }
    
    return renderGame(game);
  }

  const renderSessionDescription = () => {
    const isFirstSession = game.currentSession === 0;
      return <><div>
        <h2>{ isFirstSession ? "The Game has started !" : "The session has ended"}</h2>
        { isFirstSession && <div>Prepare yourself into an amazing journey of 5 random mini-games.</div> }
        { !isFirstSession && <div>Congratulations ! Here is the leaderboard :</div> }
        <div className="starting">
          {game.users
            .filter(a => !a.deletedAt && a.readyAt)
            .map(u => <div><b>{u.userName}</b>: {u.victory}🏆</div>)}
        </div>
      </div>
      <h2>Session {game.currentSession +1} is about to start !</h2>
      </>
  }

  const renderGamePanel = () =>  <div className="card-50 gap-20 flex flex-column">
      <div className="card card-100">
        <div className="game-title">
          <h2>Game {game._id}</h2>
          { renderActionButtons() }
        </div>
        {renderGameInformations(game)}
      </div>
      <div className="card card-100">
        {renderGameBoard()}
      </div>
  </div>

  const renderParticipantPanel = () => <div className="card card-25 full-height">
    <h2>Participants ({game.users?.filter(a => !a.deletedAt).length}) </h2>
    <div className="flex flex-column">
      { game.users?.filter(a => !a.deletedAt).map(u => renderUser(u))}
    </div>
  </div>

  const postChatMessage = async (e) => {
      e.preventDefault();
      await gameService.chat(game._id, chatMessage);
      setChatMessage("");
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      postChatMessage(e);
    }
  };

  const onChangeChatMessage = (e) => setChatMessage(e.target.value);

  const renderChatPanel = () => <div className="card card-25 full-height">
    <h2>Chat</h2>
    <div className="chat-input">
      <input type="text" onChange={onChangeChatMessage} onSubmit={postChatMessage} value={chatMessage} onKeyPress={handleKeyPress} />
    </div>
    {game.chat.map((m, index) => <div className="chat-messages" key={index}>
      <div className="chat-avatar">
          <Avatar round />
          <div  className="chat-name">
            <div>{m.userName}</div>
            <div>{m.createdAt}</div>
          </div>
        </div>
        <div>{m.message}</div>
    </div>
    )}
  </div>

  return (
    <div className="page game">
      { game ? renderGameContainer() : renderJoiningGame() }
    </div>
  );
};

export default Game;
