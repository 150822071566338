import { useEffect, useState } from "react";
import { formatAddress, handleCopyBlockchainAddress } from "../helpers/utils";

export const Great = () => {
  const [isMobile, setIsMobile] = useState();

  const handleMediaChange = () => {
    const value = window.matchMedia("(max-width:1380px)");
    setIsMobile(value.matches);
  };

  const handleCopy = () => {
    handleCopyBlockchainAddress(process.env.REACT_APP_ADDRESS_CHUCK);
  };

  useEffect(() => {
    handleMediaChange();
    window.addEventListener("resize", handleMediaChange);

    return () => {
      window.removeEventListener("resize", handleMediaChange);
    };
  }, []);

  return (
    <div className="great-container">
      <div className="great">WE ARE</div>
      <div className="great">$CHUCK</div>
      <div className="part">
        <div className="part-left">
          <img src="/banner3.png" alt="Chuck" async />
        </div>
        <div className="part-right">
          <div className="great-right-container">
            <div className="great-title">TOKENOMICS</div>
            <div
              className="great-content"
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "2px",
              }}
            >
              <span>$CHUCK’s contract address is </span>
              <b>
                <a
                  href={`https://snowtrace.dev/address/${process.env.REACT_APP_ADDRESS_CHUCK}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "white", pointerEvents: "initial" }}
                >
                  {isMobile
                    ? formatAddress(process.env.REACT_APP_ADDRESS_CHUCK)
                    : process.env.REACT_APP_ADDRESS_CHUCK}
                </a>
              </b>
              <div
                style={{
                  marginLeft: "5px",
                  marginRight: "5px",
                  cursor: "pointer",
                  pointerEvents: "initial",
                }}
                onClick={handleCopy}
              >
                <img
                  src="/icon/copy.svg"
                  alt="copy"
                  width="20px"
                  height="20px"
                />
              </div>
              There is a total supply of{" "}
              <b style={{ marginLeft: "5px" }}>202 000 000 000</b> $CHUCK
              Initial liquidity will be provided and LP tokens will be locked.
            </div>
          </div>
          <div className="great-right-container">
            <div className="great-title">DISCLAIMERS</div>
            <div className="great-content">
              $CHUCK is a meme coin with no intrinsic value or expectation of
              financial return. There is no formal team or roadmap. The coin is
              completely useless and for entertainment purposes only. Chuck
              loves you anyways.
            </div>
          </div>
          <div className="great-right-container">
            <div className="great-title">MORE TO COME</div>
            <div className="great-content">
              Stay tuned for an abundance of thrilling developments with Chuck.
              More exciting things are on the way with this fluffy and lovely
              mascot !
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
