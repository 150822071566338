import { useEffect } from "react";
import axios from "axios";

const Callback = () => {
  useEffect(() => {
    if (window.location.search) {
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/auth/twitter${window.location.search}`
        )
        .then((res) => {
          if (typeof res.data === "object") {
            window.opener.postMessage(
              JSON.stringify({ ...res.data, pass: "chuckisthebest" }),
              window.location.origin
            );
          }
        });
    }
  }, []);
  return <span>loading...</span>;
};
export default Callback;
