import { Banner } from "../../components/banner";
import { Cute } from "../../components/cute";
import { Great } from "../../components/great";
import { Meet } from "../../components/meet";
import Snowfall from "react-snowfall";

const Home = () => {
  return (
    <>
      <Snowfall />
      <Banner />
      <Meet />
      <Cute />
      <Great />
    </>
  );
};
export default Home;
