import { Link, useNavigate } from "react-router-dom";
import { useWeb3 } from "../context/web3.context";
import Web3 from "web3";
import { formatAddress } from "../helpers/utils";
import { useEffect, useRef, useState } from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { setAxiosAddress } from "../helpers/axios";
import survivorAbi from "../abi/Survivor.json";
import cookieAbi from "../abi/Cookies.json";
import stakingAbi from "../abi/Staking.json";
import ERC721Abi from "../abi/ERC721.json";
import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import MetaMaskOnboarding from "@metamask/onboarding";
import { DiscordIcon } from "./icons/discordIcon";
import { XIcon } from "./icons/xIcon";
import { SnowtraceIcon } from "./icons/snowtraceIcon";
import { DexscreenerIcon } from "./icons/dexscreenerIcon";
import { NavMenu } from "./navmenu";
import { CookieIcon } from "./icons/cookieIcon";
import { authService } from "../services/auth.service";
import { NftIcon } from "./icons/nftIcon";
import { PlayIcon } from "./icons/playIcon";
import { LeaderboardIcon } from "./icons/leaderboardIcon";
import { BuyIcon } from "./icons/buyIcon";

export const Header = () => {
  const navigate = useNavigate();
  const {
    setWeb3Instance,
    account,
    setAccount,
    setContracts,
    logout,
    setChain,
    handleIsSurvivor,
    cookieWallet,
  } = useWeb3();
  const [openDrawer, setOpenDrawer] = useState(false);
  const onBoarding = useRef();

  const handleConnect = async () => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      const newAccount = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const chainId = await window.ethereum.request({
        method: "eth_chainId",
      });
      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(
        survivorAbi.abi,
        process.env.REACT_APP_NFT_SURVIVOR_ADDRESS
      );
      const contractCookies = new web3.eth.Contract(
        cookieAbi.abi,
        process.env.REACT_APP_COOKIE_ADDRESS
      );
      const contractStaking = new web3.eth.Contract(
        stakingAbi.abi,
        process.env.REACT_APP_STAKING_ADDRESS
      );
      const contractChuck = new web3.eth.Contract(
        cookieAbi.abi,
        process.env.REACT_APP_ADDRESS_CHUCK
      );
      const contractChuckNft = new web3.eth.Contract(
        ERC721Abi.abi,
        process.env.REACT_APP_ADDRESS_CHUCK_NFT
      );
      setWeb3Instance(web3);
      setContracts({
        survivor: contract,
        cookies: contractCookies,
        staking: contractStaking,
        chuck: contractChuck,
        chuckNft: contractChuckNft,
      });
      setAccount(newAccount?.[0]);
      setAxiosAddress(newAccount?.[0]);
      setChain(chainId);
      handleIsSurvivor(newAccount?.[0]);
      localStorage.setItem("connected-web3", true);
    } else {
      onBoarding.current.startOnboarding();
    }
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleInit = async () => {
    if (window?.ethereum && localStorage.getItem("connected-web3")) {
      const web3 = new Web3(window.ethereum);
      const res = await web3.eth.getAccounts();
      const contract = new web3.eth.Contract(
        survivorAbi.abi,
        process.env.REACT_APP_NFT_SURVIVOR_ADDRESS
      );
      const contractCookies = new web3.eth.Contract(
        cookieAbi.abi,
        process.env.REACT_APP_COOKIE_ADDRESS
      );
      const contractStaking = new web3.eth.Contract(
        stakingAbi.abi,
        process.env.REACT_APP_STAKING_ADDRESS
      ); 
      const contractChuck = new web3.eth.Contract(
        cookieAbi.abi,
        process.env.REACT_APP_ADDRESS_CHUCK
      );
      const contractChuckNft = new web3.eth.Contract(
        ERC721Abi.abi,
        process.env.REACT_APP_ADDRESS_CHUCK_NFT
      );
      if (res?.length > 0) {
        const chainId = await window.ethereum.request({
          method: "eth_chainId",
        });
        setAccount(res?.[0]);
        setAxiosAddress(res?.[0]);
        await authService.login().data?.id;
        setWeb3Instance(web3);
        setContracts({
          survivor: contract,
          cookies: contractCookies,
          staking: contractStaking,
          chuck: contractChuck,
          chuckNft: contractChuckNft,
        });
        setChain(chainId);
        handleIsSurvivor(res?.[0]);
      }
    }
  };

  const handleLogout = () => {
    logout();
  };
  /*
  const handleRegister = () => {
    authService
      .register({
        address: account,
        twitter: valueEvent?.screen_name,
        oauth_token: valueEvent?.oauth_token,
        oauth_token_secret: valueEvent?.oauth_token_secret,
      })
      .then((response) => setSubmitted("OK"))
      .catch((error) => {
        console.log(error)
      });
  };*/

  useEffect(() => {
    if (!onBoarding.current) {
      onBoarding.current = new MetaMaskOnboarding();
    }
    handleInit();
  }, []);

  return (
    <header>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigate("/");
        }}
      >
        <div
          onClick={() => {
            setOpenDrawer(true);
          }}
          className="burger-content"
        >
          <img src="/icon/burger.svg" alt="burger" className="burger" />
        </div>
        <img src="/logo.png" alt="Chuck" async />
        <h1 className="title-chuck">CHUCK.</h1>
        <h1 className="title-avax">AVAX</h1>
      </div>
      <div>
        <div className="list-link">
          <BuyIcon />
          <PlayIcon />
          <LeaderboardIcon />
          <CookieIcon cookie={cookieWallet} disabled={!account} />
          <NftIcon />
          <DiscordIcon ml={true} />
          <DexscreenerIcon />
          <SnowtraceIcon />
          <XIcon />
        </div>
        {!account ? (
          <div
            className="buy-now-link"
            onClick={handleConnect}
            style={{ cursor: "pointer" }}
          >
            <div className="buy-now">CONNECT WALLET</div>
          </div>
        ) : (
          <Menu
            menuButton={
              <div
                className="buy-now-link"
                onClick={handleConnect}
                style={{ cursor: "pointer" }}
              >
                <div className="buy-now">{formatAddress(account)}</div>
              </div>
            }
            align="center"
            gap={5}
            arrow
            transition
          >
            <MenuItem>
              <Link to="/account">Account</Link>
            </MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        )}
        <Drawer
          open={openDrawer}
          onClose={() => {
            setOpenDrawer(false);
          }}
          direction="left"
          className="drawer-container"
        >
          <div className="list-menu-drawer">
            <div className="list-link-footer flex-column gap-10" >
              <BuyIcon title="Buy" />
              <PlayIcon title="Play"  />
              <LeaderboardIcon title="Leaderboard" />
              <CookieIcon cookie={cookieWallet} disabled={!account} title="Cookies" />
              <NftIcon title="NFT Collections" />
              <DiscordIcon title="Discord" />
              <DexscreenerIcon title="DexScreener" />
              <SnowtraceIcon title="Snowtrace" />
              <XIcon title="Twitter" />
            </div>
          </div>
        </Drawer>
      </div>
    </header>
  );
};
