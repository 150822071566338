export const Banner = () => {

    return <div className="banner">
        <div className="part-left">
            <h1>$CHUCK</h1>
            <img src="/banner1.png" alt="Chuck" async />
        </div>
        <div className="part-right">
            <div className="subtitle">
                <p>LET'S RIDE THIS</p>
                <p className="outline">AVALANCHE</p>
                <p className="last">TOGETHER</p>
            </div>
            <div className="bulletpoints">
                <ul>
                    <li>No allocations</li>
                    <li>No presale</li>
                    <li>No taxes</li>
                    <li>No bullsh*t</li>
                </ul>
            </div>
        </div>
    </div>
}