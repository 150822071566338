export const BuyIcon = (props) => <a
    href="https://traderjoexyz.com/avalanche/trade?inputCurrency=AVAX&outputCurrency=0xeF929078661a5a4b9493311f55b6df6D3Db77628"    
    className={`x-link ${props.ml ? "ml-2" : ""} ${props.title ? "title" : ""}`}
    target="_blank"
    rel="noreferrer"
    title="Buy $CHUCK"
    >
    <div className="x">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32px" height="32px" xlink="http://www.w3.org/1999/xlink">
        <g transform="translate(0.000000,0.000000) scale(0.500000,0.500000)">
            <path fill="currentColor" d="M 30.5,4.5 C 31.8734,4.34332 33.2068,4.50999 34.5,5C 35.2744,7.405 35.7744,9.905 36,12.5C 41.2556,10.9057 42.7556,12.739 40.5,18C 37.8963,20.2859 35.063,22.1193 32,23.5C 27.8333,22 25,19.1667 23.5,15C 23.7003,11.7155 25.2003,10.8821 28,12.5C 28.0971,9.51772 28.9304,6.85106 30.5,4.5 Z"/>
            <path fill="currentColor" d="M 4.5,7.5 C 8.91133,7.02095 13.078,7.68762 17,9.5C 18.4821,14.3926 19.3154,19.3926 19.5,24.5C 27.84,24.6664 36.1733,24.4997 44.5,24C 45.6123,19.5006 46.779,15.0006 48,10.5C 51.544,7.68038 54.044,8.51371 55.5,13C 54.5885,18.2241 53.4218,23.3907 52,28.5C 50.8333,29.6667 49.6667,30.8333 48.5,32C 39.5062,32.4998 30.5062,32.6664 21.5,32.5C 21.5,33.5 21.5,34.5 21.5,35.5C 30.5542,35.1944 39.5542,35.5278 48.5,36.5C 49.9377,38.9977 49.6044,41.1644 47.5,43C 37.0675,43.9505 26.7341,43.6172 16.5,42C 15.2986,41.0975 14.4652,39.9308 14,38.5C 12.4128,30.8888 11.2461,23.2221 10.5,15.5C 7.75312,15.8134 5.08645,15.48 2.5,14.5C 1.13868,11.5842 1.80535,9.25091 4.5,7.5 Z"/>
            <path fill="currentColor" d="M 20.5,44.5 C 28.8773,44.2743 30.544,47.441 25.5,54C 18.4741,53.7595 16.8074,50.5928 20.5,44.5 Z"/>
            <path fill="currentColor" d="M 40.5,44.5 C 46.9889,44.1563 48.9889,46.9896 46.5,53C 40.1475,55.3989 37.6475,53.2323 39,46.5C 39.7172,45.9558 40.2172,45.2891 40.5,44.5 Z"/>
            </g>
        </svg>
    </div>
    {props.title}
</a>
