import { useEffect, useState } from "react";
import { cookieService } from "../../../services/cookie.service";
import { useWeb3 } from "../../../context/web3.context";

const CookieLeaderboard = () => {
    const [result, setResult] = useState(null);
    const { account } = useWeb3();

    useEffect(() => {
       fetch() ;
    }, [account]);

    const fetch = async () => {
        const response = await cookieService.getAll();
        setResult(response.data);
    }

    const renderIcon = (i) => {
        if (i === 0 ) return <p className="leadertopicon">🥇</p>
        if (i === 1 ) return <p className="leadertopicon">🥈</p>
        if (i === 2 ) return <p className="leadertopicon">🥉</p>
        return <p className="leadericon">{i + 1}</p>;
    };

    const renderAddress = (a) => {
        return <i className="address" title={a}>{a.slice(0, 5)}...{a.slice(-5)}</i>;
    }

    return (
        <div className="page">
            <div className="part">
            <div className="staking-point">TOP COOKIE MASTERS</div>
            <div className="flex flex-center">
                <div className="cute-container">
                    <div className="part-left">
                        { account && result?.me > 0 && <p>Congratz ! You are on position <b>{result.me}</b>.</p> }
                        { account && result?.me <= 0 && <p>Hey you are not on the leaderboard :/</p> }
                        <div className="table">
                        {result?.leaderboard?.map((t, index) => (
                            <div className="table-row">
                                <div>{renderIcon(index)} {t.userName} {renderAddress(t.address)}</div>
                                <div>{t.cookies}</div>
                            </div>
                        ))}
                        </div>
                        <p><i>The leaderboard is refreshed every 15 minutes.</i></p>
                    </div>
                </div>
                <div className="part-right">
                <img
                    src="/banner4.png"
                    alt="Chuck"
                    width={500}
                    height={500}
                    async
                />
                </div>
            </div>
            </div>
        </div>
        );
};

export default CookieLeaderboard;