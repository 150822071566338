import { useEffect, useState } from "react";
import { useWeb3 } from "../../context/web3.context";
import { authService } from "../../services/auth.service";
import { Avatar } from "../../components/avatar";

const Account = () => {
  const [label, setLabel] = useState("UPDATE");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const { account, isSurvivor } = useWeb3();
  const onChangeUserName = (e) => {
    setUserName(e.target.value);
    setLabel("UPDATE");
  };
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    setLabel("UPDATE");
  };

  useEffect(() => {
    if (account) {
      authService.get(account).then((res) => {
        setUserName(res?.data?.data?.userName || "");
        setEmail(res?.data?.data?.email || "");
      });
    }
  }, [account]);

  const update = () => {
    authService.update({ userName, email }).then((res) => {
      setLabel("ACCOUNT UPDATED");
      setUserName(res.data.userName);
      setEmail(res.data.data.email);
    });
  };

  return (
    <div className="page">
      <div className="part">
        <h1>ACCOUNT</h1>
        <div className="flex flex-center">
          <div className="relative">
            <div className="card flex flex-column gap-20">
              <b>Personal informations</b>
              <input
                value={userName}
                onChange={onChangeUserName}
                placeholder="Your username"
                name="username"
                type="text"
                disabled={!account}
                className="staking-input"
              />
              <input
                value={email}
                onChange={onChangeEmail}
                placeholder="Your email"
                name="email"
                type="email"
                disabled={!account}
                className="staking-input"
              />
              {isSurvivor && <div>✅ I am a Survivor.</div>}
              <button
                onClick={account && userName && update}
                disabled={!account || !userName}
                style={{ cursor: !account ? "default" : "pointer" }}
              >
                {account ? label : "NOT CONNECTED"}
              </button>
            </div>
          </div>
          <div className="relative">
            <div className="card flex flex-column gap-20">
              <b>Avatar</b>
              <div>You have these avatars (coming soon) : </div>
              <Avatar selected />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
