/* eslint-disable jsx-a11y/alt-text */
import { Link } from "react-router-dom";
import { Great } from "../../components/great";
import { useState } from "react";
import "react-tabs/style/react-tabs.css";
import { useWeb3 } from "../../context/web3.context";
import SwitchNetwork from "../../components/switch-network";
import StakeContent from "./StakeContent";
import CookieLeaderboard from "./CookieLeaderboard";
import StakeNftContent from "./StakeNftContent";

const Cookies = () => {
  const { cookieWallet } = useWeb3();
  const [modalSwitchIsOpen, setModalSwitchIsOpen] = useState(false);

  return (
    <>
      <div className="page">
        <div className="part">
          <h1>COOKIES</h1>
          <div className="flex mb-10">
            <div className="part-left banner flex-column">
              <div className="staking-point">
                YOU CURRENTLY HAVE {Math.floor(cookieWallet)} COOKIES
              </div>
              <div className="bulletpoints">
                <p>Cookies reflects your loyalty within the Chuck ecosystem.</p>
                <div>
                  By being loyal to $CHUCK, you can enjoy many future benefits
                  such as:
                </div>
                <ul>
                  <li>
                    Monthly rewards (check your position in the{" "}
                    <Link to="/leaderboard">leaderboard</Link>).
                  </li>
                  <li>
                    Discounts for playing $CHUCK mini-games (the more you play
                    and win, the more cookies you'll receive - 80% of proceeds
                    go to Chuckers).
                  </li>
                  <li>
                    Exclusive whitelists for NFT launches organized in
                    collaboration with Chuck partners.
                  </li>
                  <li>Airdrops and much more to come!</li>
                </ul>
              </div>
            </div>
            <div className="part-right">
              <img
                src="/banner5.png"
                alt="Chuck"
                width={500}
                height={500}
                async
              />
            </div>
          </div>
        </div>
        <div className="part">
          <StakeContent setModalSwitchIsOpen={setModalSwitchIsOpen} />
          { window.location.search === "?test" && <StakeNftContent setModalSwitchIsOpen={setModalSwitchIsOpen} /> }
        </div>
        <CookieLeaderboard />
        <Great />
      </div>
      {modalSwitchIsOpen && (
        <SwitchNetwork
          modalIsOpen={modalSwitchIsOpen}
          setModalIsOpen={setModalSwitchIsOpen}
        />
      )}
    </>
  );
};
export default Cookies;
