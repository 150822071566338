import axios from "../helpers/axios";

class AuthService {
  async register(parameters) {
    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/auth/register`,
      parameters
    );
  }

  async login(parameters) {
    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/auth/login`,
      parameters
    );
  }

  async get() {
    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/auth`
    );
  }

  async update(parameters) {
    return axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/auth`, parameters
    );
  }
}

export const authService = new AuthService();
