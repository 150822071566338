import Web3 from "web3";
import survivorAbi from "../abi/Survivor.json";
import { ethers } from "ethers";

export const whitelistAddressChuck = async (
  ownerAddress,
  listAddress,
  abi,
  contractAddress
) => {
  const web3 = new Web3(window.ethereum);
  const contract = new web3.eth.Contract(abi, contractAddress);
  return contract.methods.whitelistAddressChuck(listAddress).send({
    from: ownerAddress,
  });
};

export const mint = async (contract, signature, address) => {
  return contract.methods.mint(signature).send({
    from: address,
  });
};

export const getTokenIdOf = async (address) => {
  const web3 = new Web3(process.env.REACT_APP_RPC);
  const contractVal = new web3.eth.Contract(
    survivorAbi.abi,
    process.env.REACT_APP_NFT_SURVIVOR_ADDRESS
  );
  return contractVal.methods.getTokenIdOf(address).call();
};

export const getStakerInfo = async (address, contractStaking) => {
  return contractStaking.methods.getStakerInfo(address).call();
};

export const staking = async (
  address,
  value,
  contractCookie,
  contractStaking,
  contractChuck
) => {
  const isAllow = await contractChuck.methods
    .allowance(address, process.env.REACT_APP_STAKING_ADDRESS)
    .call();
  if (Number(ethers.utils.formatEther(isAllow)) >= value) {
    await contractStaking.methods
      .stake(`${ethers.utils.parseUnits(`${value}`, "ether")}`)
      .send({
        from: address,
      });
  } else {
    await contractChuck.methods
      .approve(
        process.env.REACT_APP_STAKING_ADDRESS,
        `${ethers.utils.parseUnits(`${value}`, "ether")}`
      )
      .send({ from: address });
    await contractStaking.methods
      .stake(`${ethers.utils.parseUnits(`${value}`, "ether")}`)
      .send({
        from: address,
      });
  }
};

export const unstaking = async (contractStaking, value, address) => {
  return contractStaking.methods
    .unstake(`${ethers.utils.parseUnits(`${value}`, "ether")}`)
    .send({
      from: address,
    });
};

export const claim = async (contractStaking, address) => {
  return contractStaking.methods.claim().send({ from: address });
};

export const getReward = async (contractStaking, address) => {
  return contractStaking.methods.getReward(address).call();
};

export const balanceOf = async (
  contractChuck,
  address,
  contractCookie = null
) => {
  if (contractCookie) {
    return contractCookie.methods.balanceOf(address).call();
  }
  return contractChuck.methods.balanceOf(address).call();
};

export const stakeNft = async (
  contractStaking,
  contractChuckNft,
  tokenIds,
  address
) => {
  await contractChuckNft.methods
    .setApprovalForAll(process.env.REACT_APP_STAKING_ADDRESS, true)
    .send({ from: address });
  if (tokenIds?.length > 1) {
    return contractStaking.methods.stakeBatchChuckNFT(tokenIds).send({
      from: address,
    });
  } else {
    return contractStaking.methods.stakeChuckNFT(tokenIds?.[0]).send({
      from: address,
    });
  }
};

export const unstakeNft = async (contractStaking, tokenIds, address) => {
  if (tokenIds?.length > 1) {
    return contractStaking.methods.unstakeBatchChuckNFT(tokenIds).send({
      from: address,
    });
  } else {
    return contractStaking.methods.unstakeChuckNFT(tokenIds?.[0]).send({
      from: address,
    });
  }
};

export const getAllTokenIdStaked = async (contractStaking, address) => {
  return contractStaking.methods.getAllTokenIdStaked(address).call();
};
