const Leaderboard = () => {
  const table = [
    { title: "Anonymous Chuck", value: "1🥇" },
    { title: "Anonymous Chuck", value: "2🥈" },
    { title: "Anonymous Chuck", value: "3🥉" },
    { title: "Anonymous Chuck", value: "4" },
    { title: "Anonymous Chuck", value: "5" },
    { title: "Anonymous Chuck", value: "6" },
    { title: "Anonymous Chuck", value: "7" },
    { title: "Anonymous Chuck", value: "8" },
    { title: "Anonymous Chuck", value: "9" },
    { title: "Anonymous Chuck", value: "10" },
  ];

  return (
    <div className="page">
      <div className="part">
        <h1>LEADERBOARD</h1>
        <div className="flex flex-center">
          <div className="cute-container">
            <div className="part-left">
              <p>No active Season. Coming soon.</p>
              <div className="table">
                {table.map((t) => (
                  <div className="table-row">
                    <div>{t.value}</div>
                    <div>{t.title}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="part-right">
            <img
              src="/banner6.png"
              alt="Chuck"
              width={500}
              height={500}
              async
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
