import { useEffect, useState } from "react";
import "./style.css";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const ItemTypePiece = 'PUZZLE_PIECE';
const ItemTypeBoard = 'PUZZLE_BOARD';
const puzzleSize = 120;

export const Puzzle = () => {
  const [puzzlePieces, setPuzzlePieces] = useState([]);
  const [puzzleNumber, setPuzzleNumber] = useState(1);
  const [board, setBoard] = useState([]);
  const puzzleDimension = 6;

  const generateGame = (puzzleNumber, dimension) => {
    const puzzles = [...Array(dimension*dimension).keys()].map(i =>
      { return { id: i, puzzle: puzzleNumber, image: i }});

    const random = [...Array(dimension*dimension).keys()];
    random.sort(() => Math.random() - 0.5);
    puzzles.forEach((p, index) => p.image = random[index]);

    setPuzzlePieces(puzzles);
    setBoard(
      [...Array(dimension*dimension).keys()].map(i =>
        { return { id: i, puzzle: puzzleNumber, image: null, isFinalPosition: false }}));
    setPuzzleNumber(Math.ceil(Math.random()*5));
  }

  useEffect(() => {
    generateGame(puzzleNumber, puzzleDimension);
  }, []);

  const handleDropBoard = (item, targetId) => {
    if (item.image == null)
      return;

    const b = board.find(b => b.id === targetId);
    const p = puzzlePieces.find(p => p.id === item.id);
    if (b && b?.image == null) {
      b.image = p.image;
      b.isFinalPosition = p.image === targetId;
      p.image = null;
      
      setPuzzlePieces([...puzzlePieces]);
      setBoard([...board]);
    }
  };

  const handleDropPiece = (item, targetId) => {
    if (item.image == null)
      return;

    const b = board.find(p => p.id === item.id);
    const p = puzzlePieces.find(b => b.id === targetId);
    if (p && p?.image == null) {
      p.image = item.image;
      b.image = null;
      
      setPuzzlePieces([...puzzlePieces]);
      setBoard([...board]);
    }
  };

  return (
    <div className="page">
      <div className="">
    { !board.some(a => !a.isFinalPosition) ?
    <h1>YOU WON</h1> :
    <DndProvider backend={HTML5Backend}>
      <div style={{ display: 'flex', flexWrap: "wrap", justifyContent: "center", gap: `${puzzleSize}px`, marginTop: "50px" }}>
        <div style={{ display: 'flex', flexWrap: "wrap", width: `${puzzleDimension*puzzleSize}px`, justifyContent: "center"}}>
          {puzzlePieces.map((piece) => (
            <PuzzlePiece
              key={piece.id}
              id={piece.id}
              text={piece.text}
              image={piece.image}
              puzzle={puzzleNumber}
              isFinalPosition={piece.isFinalPosition}
              onDrop={handleDropPiece}
            />
          ))}
        </div>
        <div style={{ display: 'flex', flexWrap: "wrap", width: `${puzzleDimension*puzzleSize}px`, justifyContent: "center"  }}>
          {board.map((b) => (
            <PuzzleBoard
              key={b.id}
              id={b.id}
              text={b.text}
              puzzle={puzzleNumber}
              image={b.image}
              isFinalPosition={b.isFinalPosition}
              onDrop={handleDropBoard}
            />
          ))}
        </div>
      </div>
    </DndProvider>
    }
    </div>
    </div>
  );
}

const PuzzlePiece = ({ id, isFinalPosition, onDrop, puzzle, image }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypePiece,
    item: { id, isFinalPosition, image },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemTypeBoard,
    drop: (item) => onDrop(item, id),
  });

  return (
    <div
      ref={(node) => drag(drop(node))}
      className="puzzlePiece"
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
        width: `${puzzleSize}px`,
        height: `${puzzleSize}px`
      }}
    >
      { image != null && <img src={`/assets/games/puzzle/${puzzle}/${image}.png`} width={`${puzzleSize}px`} height={`${puzzleSize}px`} /> }
    </div>
  );
};

const PuzzleBoard = ({ id, image, isFinalPosition, onDrop, puzzle }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypeBoard,
    item: { id, isFinalPosition, image },
    collect: (monitor) => ({
      isDragging: image != null && monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemTypePiece,
    drop: (item) => onDrop(item, id),
  });

  return (
    <div
      ref={(node) => drag(drop(node))}
      className="puzzleBoard"
      style={{
        opacity: isDragging ? image != null ? 0.5 : 0 : 1,
        cursor: image != null ? 'move' : 'default',
        width: `${puzzleSize}px`,
        height: `${puzzleSize}px`,
      }}
    >
      { image != null && <img src={`/assets/games/puzzle/${puzzle}/${image}.png`} width={`${puzzleSize}px`} height={`${puzzleSize}px`} /> }
    </div>
  );
};