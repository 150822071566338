import axios from "../helpers/axios";

class GameService {
  async joinGame() {
    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/game/join`
    );
  }

  async ready(gameId) {
    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/game/ready`, gameId
    );
  }

  async leave(gameId) {
    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/game/leave`, gameId
    );
  }

  async startGame(gameId) {
    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/game/start`, gameId
    );
  }

  async chat(gameId, message) {
    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/game/chat`, { gameId, message }
    );
  }
}

export const gameService = new GameService();
