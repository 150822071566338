import { useState } from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const SwitchNetwork = ({ modalIsOpen, setModalIsOpen }) => {
  const [loading, setLoading] = useState(false);

  const handleSwitch = () => {
    try {
      setLoading(true);
      if (!window.ethereum) throw new Error("No crypto wallet found");
      window.ethereum
        .request({
          method: "wallet_addEthereumChain",
          params: [
            Number(process.env.REACT_APP_CHAIN_AVALANCHE) === 43114
              ? {
                  chainId: `0x${Number(43114).toString(16)}`,
                  chainName: "Avalanche C-Chain",
                  nativeCurrency: {
                    name: "Avalanche",
                    symbol: "AVAX",
                    decimals: 18,
                  },
                  rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
                  blockExplorerUrls: ["https://snowtrace.io"],
                }
              : {
                  chainId: `0x${Number(43113).toString(16)}`,
                  chainName: "Avalanche Fuji Testnet",
                  nativeCurrency: {
                    name: "Avalanche",
                    symbol: "AVAX",
                    decimals: 18,
                  },
                  rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
                  blockExplorerUrls: ["https://testnet.snowtrace.io/"],
                },
          ],
        })
        .then((res) => {
          setModalIsOpen(false);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <Modal
      isOpen={modalIsOpen}
      // onRequestClose={() => {
      //   setModalIsOpen(false);
      // }}
      style={customStyles}
      // contentLabel="Example Modal"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        <div>
          You must switch to network <strong>AVALANCHE</strong> to continue!
        </div>
        <div
          className="buy-now-link"
          onClick={!loading ? handleSwitch : null}
          style={{ cursor: "pointer" }}
        >
          <div
            className="buy-now"
            style={{ display: "flex", fontSize: "0.9rem" }}
          >
            {loading ? "... LOADING" : "SWITCH TO AVALANCHE"}
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default SwitchNetwork;
