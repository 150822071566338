import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useWeb3 } from "../../../context/web3.context";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  balanceOf,
  claim,
  getReward,
  getStakerInfo,
  staking,
  unstaking,
} from "../../../services/nft.service";
import { ethers } from "ethers";
import { formatBigNumber, formatStaking } from "../../../helpers/utils";

const StakeContent = ({ setModalSwitchIsOpen }) => {
  const [loading, setLoading] = useState(false);
  const [loadingClaim, setLoadingClaim] = useState(false);
  const [stakingValue, setStakingValue] = useState(0);
  const [chuck, setChuck] = useState(0);
  const [stake, setStake] = useState(0);
  const {
    account,
    isSurvivor,
    chain,
    contracts,
    cookies,
    setCookies,
    contractInitAvalanches,
    setCookieWallet,
  } = useWeb3();

  const chuckBFixed2 = useMemo(() => {
    return stake.toLocaleString("fr-FR", {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      useGrouping: true,
    });
  }, [stake]);

  const cookieGeneration = useMemo(() => {
    return ((isSurvivor ? 1.1 : 1) * (stake * 10)) / (1e9).toFixed(2);
  }, [stake, isSurvivor]);

  const onBuy = () => {
    window.open(
      "https://traderjoexyz.com/avalanche/trade?inputCurrency=AVAX&outputCurrency=0xeF929078661a5a4b9493311f55b6df6D3Db77628",
      "_blank"
    );
  };

  const handleStack = async (type) => {
    try {
      if (
        chain !==
        `0x${Number(process.env.REACT_APP_CHAIN_AVALANCHE).toString(16)}`
      ) {
        setModalSwitchIsOpen(true);
        return;
      }
      setLoading(true);
      if (type === "staking") {
        await staking(
          account,
          stakingValue,
          contracts.cookies,
          contracts.staking,
          contracts.chuck
        );
        setChuck((Number(chuck) - Number(stakingValue)).toFixed(2));
        setStake((Number(stake) + Number(stakingValue)).toFixed(2));
      } else {
        await unstaking(contracts.staking, stakingValue, account);
        setChuck((Number(chuck) + Number(stakingValue)).toFixed(2));
        setStake((Number(stake) - Number(stakingValue)).toFixed(2));
      }
      setStakingValue(0);
      const value = await getReward(contractInitAvalanches.staking, account);
      const balanceCookie = await balanceOf(
        null,
        account,
        contractInitAvalanches.cookies
      );
      setCookies(Number(ethers.utils.formatEther(value)).toFixed(2));
      setCookieWallet(Number(ethers.utils.formatEther(balanceCookie)));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClaim = async () => {
    try {
      if (
        chain !==
        `0x${Number(process.env.REACT_APP_CHAIN_AVALANCHE).toString(16)}`
      ) {
        setModalSwitchIsOpen(true);
        return;
      }
      setLoadingClaim(true);
      await claim(contracts.staking, account);
      const value = await getReward(contractInitAvalanches.staking, account);
      const balanceCookie = await balanceOf(
        null,
        account,
        contractInitAvalanches.cookies
      );
      setCookies(Number(ethers.utils.formatEther(value)).toFixed(2));
      setCookieWallet(Number(ethers.utils.formatEther(balanceCookie)));
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingClaim(false);
    }
  };

  const onChange = (e) => {
    setStakingValue(e.target.value);
  };

  const renderForm = (type) => {
    const disabled = !account;
    let label = "";
    if (loading) {
      label = "LOADING...";
    } else if (!account) {
      label = "NOT CONNECTED";
    } else {
      label = type === "stake" ? "STAKE $CHUCK" : "UNSTAKE $CHUCK";
    }

    return (
      <>
        <div>
          <div className="staking-header">
            <div>
              <b>$CHUCK Point Rate</b>
            </div>
            <div className="staking-getchuck">
              <Link to="#" onClick={onBuy} className="link-header">
                GET $CHUCK
              </Link>
            </div>
          </div>
          <div> 10 Cookies / day for 1B $CHUCK</div>
        </div>
        <div className="relative">
          <input
            value={stakingValue}
            onChange={onChange}
            placeholder="$CHUCK"
            name="username"
            type="number"
            disabled={!account}
            className="staking-input"
            min={0}
            max={type === "stake" ? chuck : stake}
          />
          <div
            className="absolute adornment"
            onClick={() => {
              setStakingValue(
                type === "stake"
                  ? formatStaking(`${chuck}`)
                  : formatStaking(`${stake}`)
              );
            }}
          >
            <b>
              {type === "stake"
                ? formatStaking(`${chuck}`)
                : formatStaking(`${stake}`)}
            </b>
            <span className="max">MAX</span>
          </div>
        </div>
        <button
          onClick={
            !loading && !loadingClaim
              ? () => handleStack(type === "stake" ? "staking" : "unstaking")
              : null
          }
          disabled={
            !account ||
            stakingValue <= 0 ||
            (type === "stake" && Number(stakingValue) > chuck) ||
            (type !== "stake" && Number(stakingValue) > stake)
          }
          style={{ cursor: disabled ? "default" : "pointer" }}
        >
          {label}
        </button>
      </>
    );
  };

  useEffect(() => {
    if (account) {
      balanceOf(contractInitAvalanches.chuck, account).then((res) => {
        setChuck(Number(ethers.utils.formatEther(res)));
        // setChuck(ethers.utils.formatEther(res));
      });
      getStakerInfo(account, contractInitAvalanches.staking).then((res) => {
        setStake(Number(ethers.utils.formatEther(res.stake)));
        // setStake(ethers.utils.formatEther(res.stake));
      });
    }
  }, [account]);

  return (
    <div className="flex">
      <div>
        <p>
          You can stake <b>$CHUCK</b> to bake <b>Cookies</b> as a token.
        </p>
        {isSurvivor && (
          <p>
            Since you have the Survivor NFT, you have a 10% cookie generation
            bonus.
          </p>
        )}
        <Tabs
          className="tab-staking"
          onSelect={() => {
            setStakingValue(0);
          }}
        >
          <TabList>
            <Tab>Stake</Tab>
            <Tab>Unstake</Tab>
          </TabList>
          <TabPanel>
            <div className="card flex flex-column gap-20">
              {renderForm("stake")}
            </div>
          </TabPanel>
          <TabPanel>
            <div className="card flex flex-column gap-20">
              {renderForm("unstake")}
            </div>
          </TabPanel>
        </Tabs>
      </div>
      <div className="banner-page">
        <div>
          Your <b>{chuckBFixed2} $CHUCK</b> staked
          {isSurvivor ? " and NFT Survivor boost" : ""} generate{" "}
          <b>{cookieGeneration} cookies / day</b>
        </div>
        <div className="staking-point">
          {cookies} COOKIE
          {cookies > 1 ? "S" : ""}
        </div>
        <button
          onClick={!loadingClaim && !loading ? handleClaim : null}
          disabled={stake === 0}
          style={{
            // cursor: "default",
            width: "80%",
            pointerEvents: "initial",
          }}
        >
          {loadingClaim ? "LOADING..." : "CLAIM"}
        </button>
      </div>
    </div>
  );
};

export default StakeContent;
