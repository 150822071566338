import {
  createContext,
  useState,
  useMemo,
  useContext,
  useEffect,
  useCallback,
} from "react";
import Web3 from "web3";
import survivorAbi from "../abi/Survivor.json";
import cookieAbi from "../abi/Cookies.json";
import stakingAbi from "../abi/Staking.json";
import ERC721Abi from "../abi/ERC721.json";
import { setAxiosAddress } from "../helpers/axios";
import { balanceOf, getReward, getTokenIdOf } from "../services/nft.service";
import { ethers } from "ethers";
import { authService } from "../services/auth.service";

const Web3Context = createContext({});

export const Web3Provider = ({ children }) => {
  const [web3Instance, setWeb3Instance] = useState();
  const [account, setAccount] = useState();
  // const [contract, setContract] = useState();
  const [contracts, setContracts] = useState({
    survivor: null,
    cookies: null,
    staking: null,
    chuck: null,
    chuckNft: null,
  });
  const [contractInitAvalanches, setContractInitAvalanches] = useState({
    survivor: null,
    cookies: null,
    staking: null,
    chuck: null,
    chuckNft: null,
  });
  const [chain, setChain] = useState();
  const [isSurvivor, setIsSurvivor] = useState(false);
  const [cookies, setCookies] = useState(0);
  const [cookieWallet, setCookieWallet] = useState();

  const handleIsSurvivor = (account) => {
    return (
      account &&
      getTokenIdOf(account).then((res) =>
      {
        setIsSurvivor(Boolean(res?.length));
      })
    );
  };

  const logout = useCallback(() => {
    setWeb3Instance(null);
    setAccount(null);
    setContracts({ survivor: null, cookies: null, staking: null, chuck: null });
    setAxiosAddress(null);
    setIsSurvivor(false);
    localStorage.removeItem("connected-web3");
  }, []);

  const value = useMemo(
    () => ({
      web3Instance,
      setWeb3Instance,
      account,
      setAccount,
      isSurvivor,
      setIsSurvivor,
      contracts,
      contractInitAvalanches,
      setContracts,
      logout,
      chain,
      setChain,
      cookies,
      setCookies,
      cookieWallet,
      setCookieWallet,
      handleIsSurvivor,
    }),
    [
      web3Instance,
      account,
      contracts,
      contractInitAvalanches,
      logout,
      chain,
      isSurvivor,
      cookies,
      cookieWallet,
    ]
  );

  const handleNewAccounts = useCallback(async () => {
    const newAccount = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(
      survivorAbi.abi,
      process.env.REACT_APP_NFT_SURVIVOR_ADDRESS
    );
    const contractCookies = new web3.eth.Contract(
      cookieAbi.abi,
      process.env.REACT_APP_COOKIE_ADDRESS
    );
    const contractStaking = new web3.eth.Contract(
      stakingAbi.abi,
      process.env.REACT_APP_STAKING_ADDRESS
    );
    const contractChuck = new web3.eth.Contract(
      cookieAbi.abi,
      process.env.REACT_APP_ADDRESS_CHUCK
    );
    const contractChuckNft = new web3.eth.Contract(
      ERC721Abi.abi,
      process.env.REACT_APP_ADDRESS_CHUCK_NFT
    );
    setWeb3Instance(web3);
    setAccount(newAccount?.[0]);
    setAxiosAddress(newAccount?.[0]);
    authService.login();
    setContracts({
      survivor: contract,
      cookies: contractCookies,
      staking: contractStaking,
      chuck: contractChuck,
      chuckNft: contractChuckNft,
    });
    handleIsSurvivor(newAccount?.[0]);
  }, []);

  const handleNetworkChanged = useCallback(async (chainId) => {
    setChain(chainId);
  }, []);

  useEffect(() => {
    const web3 = new Web3(process.env.REACT_APP_RPC);
    const contract = new web3.eth.Contract(
      survivorAbi.abi,
      process.env.REACT_APP_NFT_SURVIVOR_ADDRESS
    );
    const contractCookies = new web3.eth.Contract(
      cookieAbi.abi,
      process.env.REACT_APP_COOKIE_ADDRESS
    );
    const contractStaking = new web3.eth.Contract(
      stakingAbi.abi,
      process.env.REACT_APP_STAKING_ADDRESS
    );
    const contractChuck = new web3.eth.Contract(
      cookieAbi.abi,
      process.env.REACT_APP_ADDRESS_CHUCK
    );
    const contractChuckNft = new web3.eth.Contract(
      ERC721Abi.abi,
      process.env.REACT_APP_ADDRESS_CHUCK_NFT
    );
    setContractInitAvalanches({
      survivor: contract,
      cookies: contractCookies,
      staking: contractStaking,
      chuck: contractChuck,
      chuckNft: contractChuckNft,
    });
    window?.ethereum?.on("accountsChanged", handleNewAccounts);
    window?.ethereum?.on("chainChanged", handleNetworkChanged);
    return () => {
      window?.ethereum?.removeListener("accountsChanged", handleNewAccounts);
      window?.ethereum?.removeListener("chainChanged", handleNetworkChanged);
    };
  }, []);

  useEffect(() => {
    if (account && contractInitAvalanches) {
      getReward(contractInitAvalanches.staking, account).then((res) => {
        setCookies(Number(ethers.utils.formatEther(res)).toFixed(2));
      });
      balanceOf(null, account, contractInitAvalanches.cookies).then((res) => {
        setCookieWallet(Number(ethers.utils.formatEther(res)));
      });
    }
  }, [account, contractInitAvalanches]);

  return <Web3Context.Provider value={value}>{children}</Web3Context.Provider>;
};

export const useWeb3 = () => useContext(Web3Context);
