import { Link } from "react-router-dom";

export const LeaderboardIcon = (props) => <Link
    to="/leaderboard"
    className={`x-link ${props.ml ? "ml-2" : ""} ${props.title ? "title" : ""}`}
    title="Leaderboard"
    >
    <div className="x">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="24.000000pt" height="24.000000pt" viewBox="0 0 24.000000 24.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)"
            fill="currentColor" stroke="none">
            <path d="M50 220 c0 -5 -9 -10 -20 -10 -24 0 -24 -3 -5 -43 8 -18 29 -40 45
            -49 41 -23 49 -48 22 -66 -39 -25 -27 -42 28 -42 55 0 67 17 28 42 -27 18 -19
            43 22 66 16 9 37 31 45 49 19 40 19 43 -5 43 -11 0 -20 5 -20 10 0 6 -30 10
            -70 10 -40 0 -70 -4 -70 -10z m14 -42 c11 -44 6 -54 -14 -29 -11 13 -20 29
            -20 37 0 23 27 16 34 -8z m66 12 c0 -5 6 -10 14 -10 11 0 12 -5 3 -23 -14 -29
            -40 -29 -54 0 -9 18 -8 23 3 23 8 0 14 5 14 10 0 6 5 10 10 10 6 0 10 -4 10
            -10z m80 -8 c0 -11 -9 -27 -20 -37 -22 -20 -25 -11 -14 33 8 27 34 30 34 4z"/>
            </g>
            </svg>
    </div>
    {props.title}
</Link>
