/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import { useWeb3 } from "../../../context/web3.context";
import {
  balanceOf,
  getAllTokenIdStaked,
  getReward,
  stakeNft,
  unstakeNft,
} from "../../../services/nft.service";
import { ethers } from "ethers";
import { moralisService } from "../../../services/moralis.service";

const StakeNftContent = ({ setModalSwitchIsOpen }) => {
  const [loading, setLoading] = useState(false);
  const [loadingUnstake, setLoadingUnstake] = useState(false);
  const [loadingCursor, setLoadingCursor] = useState(false);
  const [loadingNft, setLoadingNft] = useState(true);
  const [listNfts, setListNfts] = useState([]);
  const [selectedNfts, setSelectedNfts] = useState([]);
  const [selectedNftUnstaked, setSelectedNftUnstaked] = useState([]);
  const [tokenIdStaked, setTokenIdStaked] = useState([]);
  const [cursor, setCursor] = useState();
  const {
    account,
    chain,
    contracts,
    contractInitAvalanches,
    setCookies,
    setCookieWallet,
  } = useWeb3();

  const handleSelected = (tokenId, isStaking) => {
    if (isStaking) {
      const selectedNftUnstakedClone = [...selectedNftUnstaked];
      if (selectedNftUnstakedClone?.includes(tokenId)) {
        setSelectedNftUnstaked(
          selectedNftUnstakedClone.filter((item) => item !== tokenId)
        );
      } else {
        selectedNftUnstakedClone.push(tokenId);
        setSelectedNftUnstaked(selectedNftUnstakedClone);
      }
    } else {
      const selectedNftsClone = [...selectedNfts];
      if (selectedNftsClone?.includes(tokenId)) {
        setSelectedNfts(selectedNftsClone.filter((item) => item !== tokenId));
      } else {
        selectedNftsClone.push(tokenId);
        setSelectedNfts(selectedNftsClone);
      }
    }
  };

  const handleStakeNft = async () => {
    try {
      if (
        chain !==
        `0x${Number(process.env.REACT_APP_CHAIN_AVALANCHE).toString(16)}`
      ) {
        setModalSwitchIsOpen(true);
        return;
      }
      setLoading(true);
      await stakeNft(
        contracts.staking,
        contracts.chuckNft,
        selectedNfts,
        account
      );
      const value = await getReward(contractInitAvalanches.staking, account);
      const balanceCookie = await balanceOf(
        null,
        account,
        contractInitAvalanches.cookies
      );
      setTokenIdStaked([...tokenIdStaked, ...selectedNfts]);
      setSelectedNfts([]);
      setCookies(Number(ethers.utils.formatEther(value)).toFixed(2));
      setCookieWallet(Number(ethers.utils.formatEther(balanceCookie)));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleUnstakeNft = async () => {
    try {
      if (
        chain !==
        `0x${Number(process.env.REACT_APP_CHAIN_AVALANCHE).toString(16)}`
      ) {
        setModalSwitchIsOpen(true);
        return;
      }
      setLoadingUnstake(true);
      await unstakeNft(contracts.staking, selectedNftUnstaked, account);
      const value = await getReward(contractInitAvalanches.staking, account);
      const balanceCookie = await balanceOf(
        null,
        account,
        contractInitAvalanches.cookies
      );
      const tokenIdStakedClone = [...tokenIdStaked];
      setTokenIdStaked(
        tokenIdStakedClone?.filter(
          (tokenId) => !selectedNftUnstaked.includes(Number(tokenId))
        )
      );
      setCookies(Number(ethers.utils.formatEther(value)).toFixed(2));
      setCookieWallet(Number(ethers.utils.formatEther(balanceCookie)));
      setSelectedNftUnstaked([]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingUnstake(false);
    }
  };

  const handleGetNfts = () => {
    setLoadingCursor(true);
    moralisService
      .getNftByContract(account, cursor)
      .then((res) => {
        setListNfts([...listNfts, ...res?.data?.result]);
        setCursor(res?.data?.cursor);
      })
      .finally(() => {
        setLoadingCursor(false);
      });
  };

  useEffect(() => {
    if (account) {
      Promise.all([
        getAllTokenIdStaked(contractInitAvalanches.staking, account),
        moralisService.getNftByContract(account),
      ])
        .then((res) => {
          setTokenIdStaked(res?.[0]?.map((val) => Number(val)));
          setListNfts(res?.[1]?.data?.result);
          setCursor(res?.[1]?.data?.cursor);
        })
        .finally(() => {
          setLoadingNft(false);
        });
    }
  }, [account]);

  return (
    <div className="relative mt-2 flex stakenft-content">
      <div className="card flex flex-column gap-20 width-full">
        <b>Boost your Cookie experience</b>
        <div>
          Stake your Chuck's NFTs and receive a 5% Cookie bonus for each NFT
          staked.
        </div>
        {loadingNft || listNfts?.length === 0 ? (
          <div
            className="text-center"
            style={{
              height: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: 600,
            }}
          >
            <span>{loadingNft ? "LOADING..." : "You don't have NFT"}</span>
          </div>
        ) : (
          <div
            className="list-nft"
            style={{ marginBottom: !cursor ? "20px" : "0px" }}
          >
            {listNfts?.map((item) => (
              <div
                key={item?.token_id}
                style={{ width: "150px", height: "150px", cursor: "pointer" }}
              >
                <img
                  className={`avatar  ${
                    tokenIdStaked?.includes(Number(item?.token_id))
                      ? "staking"
                      : ""
                  } ${
                    selectedNfts.includes(Number(item?.token_id)) ||
                    selectedNftUnstaked.includes(Number(item?.token_id))
                      ? "selected-black"
                      : ""
                  }`}
                  src={
                    item?.metadata?.length > 0
                      ? JSON.parse(item.metadata)?.image
                      : "/assets/avatar/default.jpg"
                  }
                  onClick={() =>
                    handleSelected(
                      Number(item?.token_id),
                      tokenIdStaked?.includes(Number(item?.token_id))
                    )
                  }
                />
              </div>
            ))}
          </div>
        )}

        {cursor && (
          <div className="text-center" style={{ marginBottom: "20px" }}>
            <span
              style={{
                textDecoration: !loadingCursor ? "underline" : "",
                fontWeight: 500,
                cursor: "pointer",
                color: "red",
              }}
              onClick={!loadingCursor ? handleGetNfts : null}
            >
              {loadingCursor ? "LOADING..." : "SEE MORE"}
            </span>
          </div>
        )}
        <div className="text-center staking-button">
          <button
            onClick={!loading && !loadingUnstake ? handleStakeNft : null}
            style={{
              pointerEvents: "initial",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
            disabled={selectedNfts.length === 0}
          >
            {loading ? "LOADING..." : "STAKE NFT"}
          </button>
          <button
            onClick={!loadingUnstake && !loading ? handleUnstakeNft : null}
            style={{
              pointerEvents: "initial",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
            disabled={selectedNftUnstaked.length === 0}
          >
            {loadingUnstake ? "LOADING..." : "UNSTAKE NFT"}
          </button>
        </div>
      </div>
    </div>
  );
};
export default StakeNftContent;
