export const Avatar = ({ address, selected, name, small, round }) => {
  const url = address ?? "/assets/avatar/default.jpg";

  return (
    <div className={ `${small ||round ? "" : "avatarContainer"} ${round ? "round" : ""}` }>
      <img className={`avatar ${selected ? "selected" : ""} ${small ? "small" : ""} ${round ? "round" : ""}`} src={url} />
      {!small && !round && <b>Default Chuck</b> }
    </div>
  );
};
