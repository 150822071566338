export const formatAddress = (address) => {
  if (address?.length > 0) {
    return `${address.substring(0, 5)} ... ${address.substring(
      address.length - 5
    )}`;
  }
  return null;
};

export const handleCopyBlockchainAddress = (address) => {
  navigator.clipboard.writeText(address);
};

export const formatStaking = (value) => {
  return Number(formatBigNumber(value)).toFixed(2);
}

export const formatBigNumber = (value) => {
  if (value === "0" || value === 0) {
    return 0;
  }
  return value.replace(
    /^([+-])?(\d+).?(\d*)[eE]([-+]?\d+)$/,
    function (x, s, n, f, c) {
      var l = +c < 0,
        i = n.length + +c,
        x = (l ? n : f).length,
        c = (c = Math.abs(c)) >= x ? c - x + l : 0,
        z = new Array(c + 1).join("0"),
        r = n + f;
      return (
        (s || "") +
        (l ? (r = z + r) : (r += z)).substr(0, (i += l ? z.length : 0)) +
        (i < r.length ? "." + r.substr(i) : "")
      );
    }
  );
};
