export const Cute = () => {

    const table = [
        { title: "Buy Tax", value: "0%"},
        { title: "Sell Tax", value: "0%"},
        { title: "Tax modifiable", value: "No"},
        { title: "Ownership renounced", value: "Yes"},
        { title: "Hidden owner", value: "No"},
        { title: "Open source", value: "Yes"},
        { title: "Honeypot", value: "No"},
        { title: "Proxy contract", value: "No"},
        { title: "Mintable", value: "No"},
        { title: "Transfer pausable", value: "No"},
        { title: "Trading cooldown", value: "No"},
        { title: "Can't sell all", value: "No"},
        { title: "Owner can change balance", value: "No"},
        { title: "Has blacklist", value: "No"},
        { title: "Has whitelist", value: "Yes"},
        { title: "Is anti whale", value: "Yes"}
    ];

    return <div className="cute-container">
        <div className="part-left">
            <div className="cute">HE'S NOT JUST CUTE,</div>
            <div className="strong">HE'S STRONG</div>
            <div className="table">
                { table.map((t) => <div key={t.title} className="table-row">
                    <div>{t.title}</div>
                    <div>{t.value} ✅</div>
                </div>)}
            </div>
        </div>
        <div className="part-right">
            <img src="/banner2.png" alt="Chuck" async />
        </div>
    </div>
}