import { Link } from "react-router-dom"
import { DiscordIcon } from "./icons/discordIcon";
import { XIcon } from "./icons/xIcon";
import { DexscreenerIcon } from "./icons/dexscreenerIcon";
import { SnowtraceIcon } from "./icons/snowtraceIcon";
import { NavMenu } from "./navmenu";
import { AvaxIcon } from "./icons/avaxIcon";
import { BuyIcon } from "./icons/buyIcon";
import { PlayIcon } from "./icons/playIcon";
import { LeaderboardIcon } from "./icons/leaderboardIcon";
import { CookieIcon } from "./icons/cookieIcon";
import { NftIcon } from "./icons/nftIcon";
import { useWeb3 } from "../context/web3.context";

export const Footer = () => {
    const {
        account,
        cookieWallet,
      } = useWeb3();
      
    return <div className="footer">
        <div className="part">
            <div className="part-right">
                <AvaxIcon />
            </div>
            <div className="list-link-footer">
                <BuyIcon />
                <PlayIcon />
                <LeaderboardIcon />
                <CookieIcon cookie={cookieWallet} disabled={!account} />
                <NftIcon />
            </div>
        </div>
        <div className="part">
            <div className="part-left">© <b>CHUCKAVAX.COM</b> 2024 — ALL RIGHTS RESERVED TO ITS COMMUNITY</div>
            <div className="part-right list-link-footer">
                <DiscordIcon />
                <DexscreenerIcon />
                <SnowtraceIcon />
                <XIcon />
            </div>
        </div>
    </div>
}