import axios from "../helpers/axios";

class CookieService {
  async getAll() {
    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/cookie/all`
    );
  }
}

export const cookieService = new CookieService();
