import { Memory } from "../pages/Games/memory";
import { Puzzle } from "../pages/Games/puzzle";

export const gameIntro = [
    {
        id: 0,
        title: "Memory Game",
        description: "Welcome to the world of the Memory Game, where every card you flip unveils epic adventures with Chuck! Chuck, the fearless hero with a thousand faces, will accompany you on this memorable journey. Get ready to team up with Chuck and explore the many facets of his incredible character through the cards you'll flip. Face the challenges that lie ahead, as each pair of cards brings you closer to victory. Are you ready to take on the challenge and test your memory with Chuck by your side? Then dive into the adventure now and let the quest for memory begin!"
    },
    {
        id: 1,
        title: "Puzzle Game",
        description: "Welcome to the captivating realm of Chuck's Puzzle Game, where each piece unveils a new facet of Chuck's thrilling escapades! Chuck, the versatile protagonist with boundless stories, will guide you through this engaging puzzle odyssey. Prepare yourself to embark on a captivating journey alongside Chuck, unraveling the mysteries hidden within each intricate puzzle piece. Confront the trials that await, as every assembly brings you closer to triumph. Are you primed to embrace the challenge and exercise your problem-solving prowess with Chuck as your steadfast companion? Embark on this exhilarating adventure now and let the quest for puzzle mastery commence!"
    }
  ];

export const renderGame = (game) => {
    const currentDate = new Date();
    
    const isPlaying = new Date(game.sessions[game.currentSession].startedAt) <= currentDate && currentDate <= new Date(game.sessions[game.currentSession].finishedAt);

    if (!isPlaying)
        return null;

    if (game.sessions[game.currentSession]?.gameType === 0)
        return <Memory game={game} />
    if (game.sessions[game.currentSession]?.gameType === 1)
        return <Puzzle game={game} />
}